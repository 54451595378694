import * as React from "react"
import styled from "styled-components"

const SlideHeader = props => {
  return (
    <>
      <StyledSlideHeader>{props.children}</StyledSlideHeader>
    </>
  )
}
export default SlideHeader

const StyledSlideHeader = styled.div`
  h2 {
    margin-top: 1em;
    font-size: 2em;
    line-height: 0.8em;
    margin-bottom: 1em;
    em {
      font-size: 1em;
    }
  }
`
