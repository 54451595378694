import * as React from "react"
import styled from "styled-components"
import { devices, dimensions } from "../../scss/js-variables"

const SlideLayout = React.forwardRef((props, ref) => {
  return (
    <StyledSlideLayout css={props.passCss ? props.passCss : {}} ref={ref}>
      <div id="" className="slider-image">
        {props.slideImg}
      </div>
      <div id="" className=" slider-title">
        {props.slideTitle}
      </div>
      <div id="" className=" slider-content">
        {props.slideContent}
      </div>
    </StyledSlideLayout>
  )
})
export default SlideLayout

const StyledSlideLayout = styled.div`
  font-size: calc(14px + 4 * ((100vw - 300px) / 1000));
  padding-top: ${dimensions["navHeight"]};
  height: 100%;
  display: grid;
  justify-content: stretch;
  align-content: stretch;
  grid-template-columns: 5% 1fr 1fr 5%;
  grid-template-rows: 1fr minmax(30vh, 5fr) 2fr;
  grid-template-areas:
    ". g-title g-title. "
    "g-image g-image g-image g-image"
    ". g-content g-content .";
  @media ${devices["$large-tabletA"]} {
    gap: 0 15px;
    grid-template-columns: 8% 54% 30% 8%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "g-image g-image . ."
      "g-image g-image g-title ."
      "g-image g-image g-content ."
      "g-image g-image . .";
  }
  .slider-image {
    grid-area: g-image;
    justify-self: center;
    align-self: flex-end;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    @media ${devices["$large-tabletA"]} {
      align-self: end;
      width: 100%;
      height: 100%;
      svg {
        margin-bottom: -1px;
      }
    }
  }
  .slider-title {
    grid-area: g-title;
    justify-self: start;
    align-self: center;
    padding-top: 1em;
    @media ${devices["$large-tabletA"]} {
      padding-top: 0em;
      align-self: end;
      h2 {
        margin-bottom: 0;
      }
    }
  }
  .slider-content {
    grid-area: g-content;
    align-self: center;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-top: 1em;
    @media ${devices["$large-tabletA"]} {
      padding-top: 0;
    }
    .candy-image {
      margin-top: 3em;
      display: none;
      @media ${devices["$large-tabletA"]} {
        display: inline-block;
      }
    }
  }
`
