import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { text } from "../../scss/js-variables"

import SlideLayout from "./slide-layout"
import SlideHeader from "./slide-header"
import SlideContent from "./slide-content"

import Woman4 from "../../../assets/slider/insk_slide4.svg"

const Slide4 = React.forwardRef((props, ref) => {
  return (
    <>
      <SlideLayout
        slideImg={<Woman4 />}
        slideTitle={<SlideTitle />}
        slideContent={<SlideText />}
        ref={ref}
      />
    </>
  )
})

export default Slide4

const SlideTitle = props => {
  return (
    <SlideHeader>
      <StyledSlideTitle>
        ¡Una dulzura
        <br />
        <em>que perdura!</em>
      </StyledSlideTitle>
    </SlideHeader>
  )
}
const StyledSlideTitle = styled.h2`
  color: ${text["text-section4_accent"]};
  em {
    color: ${text["text-section3"]};
  }
`

const SlideText = props => {
  return (
    <SlideContent>
      <StyledSlideText>
        <p className="accent">¿Sueñas con viajes lejanos?</p>
        <p>
          Prueba mis barquillos crujientes con un delicado relleno de vainilla.
          Regálate un momento de dulce placer y siéntete como en un verdadero
          viaje exótico.
        </p>
        <StaticImage
          className="candy-image"
          placeholder="none"
          loading="eager"
          src="../../images/z_candy4.png"
          alt="Don Delicja candy 4"
        />
      </StyledSlideText>
    </SlideContent>
  )
}

const StyledSlideText = styled.div`
  color: ${text["text-section2"]};
  p.accent {
    color: ${text["text-section4_accent"]};
  }
`
