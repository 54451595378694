import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { text } from "../../scss/js-variables"

import SlideLayout from "./slide-layout"
import SlideHeader from "./slide-header"
import SlideContent from "./slide-content"

import Woman3 from "../../../assets/slider/insk_slide3.svg"

const Slide3 = React.forwardRef((props, ref) => {
  return (
    <>
      <SlideLayout
        slideImg={<Woman3 />}
        slideTitle={<SlideTitle />}
        slideContent={<SlideText />}
        ref={ref}
      />
    </>
  )
})

export default Slide3

const SlideTitle = props => {
  return (
    <SlideHeader>
      <StyledSlideTitle>
        ¡Un placer suave <br />
        <em>duro de resistir!</em>
      </StyledSlideTitle>
    </SlideHeader>
  )
}
const StyledSlideTitle = styled.h2`
  color: ${text["white"]};
  em {
    color: ${text["text-section3"]};
  }
`

const SlideText = props => {
  return (
    <SlideContent>
      <StyledSlideText>
        <p className="accent">
          Una almohada se asocia con relajación agradable y momento de descanso.
        </p>
        <p>
          Fueron su ternura y forma lo que me inspiró a crear nuevas galletas,
          con un relleno de chocolate increíblemente delicado.
        </p>
        <StaticImage
          className="candy-image"
          placeholder="none"
          loading="eager"
          src="../../images/z_candy3.png"
          alt="Don Delicja candy 3"
        />
      </StyledSlideText>
    </SlideContent>
  )
}

const StyledSlideText = styled.div`
  color: ${text["white"]};
  p.accent {
    color: ${text["text-section3_accent"]};
  }
`
