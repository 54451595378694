import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { devices, text } from "../../scss/js-variables"

import ChatBubble from "../../../assets/cloud1.svg"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const Section1 = props => {
  const sectionWrapperRef = useRef()
  const chatBubbleRef = useRef()

  useEffect(() => {
    const section = sectionWrapperRef.current

    gsap.set(chatBubbleRef.current, { autoAlpha: 0, scale: 0 })

    gsap
      .to(chatBubbleRef.current, {
        autoAlpha: 1,
        scale: 1,
        ease: "elastic.out(1.3, 0.3)",
        duration: 2,
        delay: 1,
      })
      .then(mainAnimation)

    function mainAnimation() {
      const mainAnimation = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          // markers: true,
          toggleActions: "play none reverse none",
          start: "25% top",
          end: "75% bottom",
        },
      })
      const afterIntro = gsap.to(chatBubbleRef.current, {
        autoAlpha: 0,
        scale: 0.2,
        ease: "elastic.in(1.5, 0.3)",
        duration: 2.5,
      })
      mainAnimation.add(afterIntro)
      return mainAnimation
    }
  }, [])

  return (
    <StyledSection
      id={props.id}
      ref={sectionWrapperRef}
      className={
        (props.className ? props.className : "") +
        " " +
        "main-section first container r :c-10:xxs :a-tl:xxs :c-8:xs :a-tl:xs :c-6:sm :a-tl:sm :c-6:md+ :a-lc:md+ :c-6 :a-lc"
      }
    >
      <div className="wrapper z-index-fix" ref={chatBubbleRef}>
        <StyledChatBubble />
        <h1 className="title">
          <em>¡Conoce</em>
          LA DULZURA
        </h1>
        <p className="claim">que perdura!</p>
      </div>
    </StyledSection>
  )
}

const StyledChatBubble = styled(ChatBubble)`
  @media ${devices["$pcB"]} {
    &#cloud {
      #cloud_1 {
        transform: translate3d(-8%, 78%, 0);
      }
      #cloud_2 {
        transform: translate3d(-10%, 78%, 0);
      }
      #cloud_3 {
        transform: translate3d(-10%, 70%, 0);
      }
    }
  }
`
const StyledSection = styled.section`
  position: relative;
  color: ${text["white"]};
  background: url("./bg_pattern.svg") no-repeat fixed,
    url("./bg_pattern.svg") no-repeat fixed,
    url("./bg_pattern.svg") no-repeat fixed;
  background-position: top 10vh left -30vh, bottom -7vh center,
    center right -40%;
  background-size: 70vh, 40vh, 48vw;
  @media ${devices["$large-tabletA"]} {
    background-position: center left -20vw, bottom -20vw center,
      center right -40%;
    background-size: 48vw, 50vw, 48vw;
  }
  em {
    color: ${text["text-section2"]};
    display: block;
  }
  h1 {
    color: ${text["text-section1"]};
    text-align: center;
  }
  p {
    font-size: 2em;
    max-width: 470px;
    text-align: center;
  }
  .wrapper {
    position: relative;
  }
  .title,
  .claim {
    position: absolute;
    margin: 0;
  }
  .title {
    top: 50%;
    transform: translateY(-130%);
    left: 10%;
    right: 10%;
    font-size: calc(25px + 35 * ((100vw - 320px) / 1000));
  }
  .claim {
    top: 50%;
    transform: translateY(135%);
    left: 8%;
    right: 43%;
    font-size: 2.3em;
    font-size: calc(14px + 20 * ((100vw - 320px) / 1000));
  }
`

export default Section1
