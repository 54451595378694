import * as React from "react"
import styled from "styled-components"

const SlideContent = props => {
  return <StyledSlideContent>{props.children}</StyledSlideContent>
}

const StyledSlideContent = styled.div`
  p.accent {
    font-size: 1.1em;
  }
  p {
  }
`
export default SlideContent
