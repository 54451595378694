import React, { useRef, useLayoutEffect, useEffect } from "react"

import smoothscroll from "smoothscroll-polyfill"
import styled from "styled-components"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

import Layout from "../components/layout"
import DonDelicja from "../components/don-delicja"
import Section1 from "../components/es/section1"
import Section2 from "../components/es/section2"
import Section4 from "../components/es/section4"
import SwiperSlider from "../components/slider/swiper-slider"
import Cookies from "../components/es/cookies"

import Seo from "../components/seo"

import "../scss/global.scss"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const IndexPage = () => {
  const sectionContainer = useRef(null)

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  useLayoutEffect(() => {
    const sections =
      sectionContainer.current.querySelectorAll(".scroll-section")

    // modern Chrome requires { passive: false } when adding event
    let supportsPassive = false
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true
            return null
          },
        })
      )
    } catch (e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false
    var wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel"

    const scrolling = {
      enabled: true,
      // events: "scroll,wheel,touchmove,pointermove".split(","),
      events: "DOMMouseScroll,scroll,touchmove,pointermove".split(","),
      prevent: e => {
        if (e.cancelable) {
          e.preventDefault()
        }
      },
      disable() {
        if (scrolling.enabled) {
          scrolling.enabled = false
          window.addEventListener("scroll", gsap.ticker.tick, { passive: true })
          scrolling.events.forEach((e, i) =>
            (i ? document : window).addEventListener(e, scrolling.prevent, {
              passive: false,
            })
          )
          window.addEventListener(wheelEvent, scrolling.prevent, wheelOpt) // modern desktop
        }
      },
      enable() {
        if (!scrolling.enabled) {
          scrolling.enabled = true
          window.removeEventListener("scroll", gsap.ticker.tick)
          scrolling.events.forEach((e, i) =>
            (i ? document : window).removeEventListener(e, scrolling.prevent)
          )
          window.removeEventListener(wheelEvent, scrolling.prevent, wheelOpt)
        }
      },
    }

    function goToSection(section, i) {
      if (scrolling.enabled) {
        scrolling.disable()
        gsap.to(window, {
          scrollTo: { y: section, autoKill: false },
          onComplete: scrolling.enable,
          duration: 0.3,
          ease: "power3.out",
        })
      }
    }
    let SectionScrollTrigger = []
    function createSectionScrollTrigger() {
      sections.forEach((section, i) => {
        SectionScrollTrigger.push(
          ScrollTrigger.create({
            trigger: section,
            id: "section-" + i,
            // markers: true,
            start: "top bottom-=1",
            end: "bottom top+=1",
            onEnter: () => {
              if (!linkClicked) {
                goToSection(section)
              }
            },
            onEnterBack: () => {
              if (!linkClicked) {
                goToSection(section)
              }
            },
          })
        )
      })
    }

    // setTimeout(createSectionScrollTrigger(), 100)
    createSectionScrollTrigger()

    let linkClicked = false

    const triggers = SectionScrollTrigger
    const linksNav = document
      .getElementsByTagName("header")[0]
      .querySelectorAll("a")

    linksNav.forEach(link => {
      let scrollTarget = "#" + link.getAttribute("href").split("#")[1]
      link.addEventListener("click", () => {
        linkClicked = true

        scrolling.disable()
        triggers.forEach(ST => {
          ST.disable(false, true)
        })
        gsap.to(window, {
          scrollTo: { y: scrollTarget, autoKill: false },
          duration: 0.3,
          // ease: "none",
          ease: "power3.out",
          onComplete: () => {
            triggers.forEach(ST => {
              ST.enable(false, true)
              ST.refresh()
            })
            setTimeout(() => {
              scrolling.enable()
              linkClicked = false
            }, 1500)
          },
        })
      })
    })

    return () => {
      window.removeEventListener("scroll", gsap.ticker.tick)
      ScrollTrigger.kill()
      scrolling.enable()
    }
  }, [])

  return (
    <Layout ref={sectionContainer}>
      <Seo title="DOÑA DELICIA" lang="es" />
      <RelativeContainer>
        <Section1 id="hola" className="scroll-section"></Section1>
        <Section2 id="dona-delicja" className="scroll-section"></Section2>
        <DonDelicja className="fix-secound-section" />
      </RelativeContainer>
      <section
        id="galletas"
        className="main-section third scroll-section"
        style={{ position: "relative" }}
      >
        <SwiperSlider />
      </section>
      <RelativeContainer style={{ zIndex: -1 }}>
        <Section4 id="contacto" className="scroll-section"></Section4>
        <DonDelicja />
      </RelativeContainer>
      <Cookies />
    </Layout>
  )
}

const RelativeContainer = styled.div`
  position: relative;
`

export default IndexPage
