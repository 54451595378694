import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { text } from "../../scss/js-variables"

import SlideLayout from "./slide-layout"
import SlideHeader from "./slide-header"
import SlideContent from "./slide-content"

import Woman2 from "../../../assets/slider/insk_slide2.svg"

const Slide2 = React.forwardRef((props, ref) => {
  return (
    <>
      <SlideLayout
        slideImg={<Woman2 />}
        slideTitle={<SlideTitle />}
        slideContent={<SlideText />}
        ref={ref}
      />
    </>
  )
})

export default Slide2

const SlideTitle = props => {
  return (
    <SlideHeader>
      <StyledSlideTitle>
        ¡Dulce placer
        <br />
        <em>siempre a tu lado!</em>
      </StyledSlideTitle>
    </SlideHeader>
  )
}
const StyledSlideTitle = styled.h2`
  color: ${text["white"]};
  em {
    color: ${text["text-section3"]};
  }
`

const SlideText = props => {
  return (
    <SlideContent>
      <StyledSlideText>
        <p className="accent">
          Si te das un momento de placer… nada mejor que sea en version triple.
        </p>
        <p>
          Tres galletas de cacao intenso combinadas con una suave crema con
          sabor a nata es lo que elijo todos los días durante mi pausa en el
          trabajo.
        </p>
        <StaticImage
          className="candy-image"
          placeholder="none"
          loading="eager"
          src="../../images/z_candy2.png"
          alt="Don Delicja candy 2"
        />
      </StyledSlideText>
    </SlideContent>
  )
}

const StyledSlideText = styled.div`
  color: ${text["white"]};
  p.accent {
    color: ${text["text-section3_accent"]};
  }
`
