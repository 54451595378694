import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { text } from "../../scss/js-variables"

import SlideLayout from "./slide-layout"
import SlideHeader from "./slide-header"
import SlideContent from "./slide-content"

import Woman1 from "../../../assets/slider/insk_slide1.svg"

const Slide1 = React.forwardRef((props, ref) => {
  return (
    <>
      <SlideLayout
        slideImg={<Woman1 />}
        slideTitle={<SlideTitle />}
        slideContent={<SlideText />}
        ref={ref}
      />
    </>
  )
})

export default Slide1

const SlideTitle = props => {
  return (
    <SlideHeader>
      <StyledSlideTitle>
        ¡Una dulzura
        <br />
        <em>que perdura!</em>
      </StyledSlideTitle>
    </SlideHeader>
  )
}
const StyledSlideTitle = styled.h2`
  color: ${text["white"]};
  em {
    color: ${text["text-section3"]};
  }
`

const SlideText = props => {
  return (
    <SlideContent>
      <StyledSlideText>
        <p className="accent">
          Me encanta cuando el dulce placer dura el mayor tiempo posible.
        </p>
        <p>
          Por eso, independientemente de si hago viajes largos o cortos, siempre
          llevo conmigo mis barquillos crujientes, con un relleno delicado y con
          un intenso sabor a cacao.
        </p>
        <StaticImage
          className="candy-image"
          placeholder="none"
          loading="eager"
          src="../../images/z_candy1.png" 
          alt="Don Delicja candy 1"
        />
      </StyledSlideText>
    </SlideContent>
  )
}

const StyledSlideText = styled.div`
  color: ${text["white"]};
  p.accent {
    color: ${text["text-section3_accent"]};
  }
`
