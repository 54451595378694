import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { devices, text, dimensions } from "../../scss/js-variables"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const Section2 = props => {
  const sectionWrapperRef = useRef()
  const textContainerRef = useRef()

  useEffect(() => {
    const section = sectionWrapperRef.current
    const title = textContainerRef.current.getElementsByClassName("title")[0]
    const content =
      textContainerRef.current.getElementsByClassName("content")[0]

    gsap.set(title, { autoAlpha: 0, x: -100, scale: 0.9 })
    gsap.set(content.children, { autoAlpha: 0, x: -100 })

    const constTitleTween = gsap.to(title, {
      autoAlpha: 1,
      ease: "Power3.in",
      scale: 1,
      x: 0,
      duration: 1,
    })
    const constContentTween = gsap.to(content.children, {
      autoAlpha: 1,
      ease: "Power3.in",
      scale: 1,
      x: 0,
      stagger: 0.4,
      duration: 1,
    })

    const mainAnimation2 = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        // markers: true,
        toggleActions: "play reverse play reverse",
        start: "top center",
        end: "bottom center",
      },
    })
    mainAnimation2.add(constTitleTween)
    mainAnimation2.add(constContentTween)
  })

  return (
    <StyledSection
      id={props.id}
      ref={sectionWrapperRef}
      className={
        (props.className ? props.className : "") +
        " " +
        "main-section secound container r :c-12:xxs :a-tl:xxs :c-12:xs :a-tl:xs :c-6:sm+ :a-lc:sm+"
      }
    >
      <div className="z-index-fix" ref={textContainerRef}>
        <h2 className="title">
          Algunos me dicen
          <em className="display-block">
            que soy una loca aficionada a&nbsp;los dulces
          </em>
        </h2>
        <div className="content ">
          <p>
            Otros, una maestra de la repostería. Sin importar dónde esté o qué
            esté haciendo, siempre tengo una de mis galletas favoritas conmigo.
          </p>
          <p>
            <em>
              ¡Te invito a un viaje increíble por el mundo del dulce placer!
            </em>
          </p>
          <p>Será delicioso. Estará crujiente. Será como nunca.</p>
        </div>
      </div>
    </StyledSection>
  )
}
export default Section2

const StyledSection = styled.section`
  position: relative;
  color: ${text["white"]};
  background: url("./bg_pattern2.svg") no-repeat fixed,
    url("./bg_pattern2.svg") no-repeat, url("./bg_section.png") no-repeat fixed;
  background-position: top 10vh left -30vh, bottom 0vh right -30vh, center;
  background-size: 70vh, 50vh, cover;
  @media ${devices["$large-tabletA"]} {
    background: url("./bg_pattern2.svg") no-repeat fixed,
      url("./bg_pattern2.svg") no-repeat fixed,
      url("./bg_section.png") no-repeat fixed;
    background-size: 80vh, 70vh, cover;
    background-position: 30vw 40vh, 60vw 30vh, center;
  }
  em {
    color: ${text["text-section2"]};
  }
  h2 {
    em {
      margin-top: 0.5em;
      line-height: 1.4em;
    }
  }
  p {
  }
  .title {
    font-size: calc(30px + 20 * ((100vw - 320px) / 1000));
    margin-top: 5vh;
    @media ${devices["$large-tabletA"]} {
      max-width: 800px;
      em {
        max-width: 650px;
      }
    }
  }

  .content {
    @media ${devices["$large-tabletA"]} {
      max-width: 700px;
    }
  }
  @media ${devices["$pcB"]} {
    .title {
      font-size: calc(30px + 20 * ((100vw - 320px) / 1000));
      height: calc(100vh - ${dimensions["navHeight"]});
    }
    .content {
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`
