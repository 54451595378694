import React, { useRef } from "react"
import PropTypes from "prop-types"
// import styled from "styled-components"
import { Navigation, Pagination, A11y } from "swiper"
import { colors, devices } from "../../scss/js-variables"
import { Swiper, SwiperSlide } from "swiper/react"
import styled from "styled-components"
import gsap from "gsap"

import "./slider.scss"

import Slide1 from "./slide1"
import Slide2 from "./slide2"
import Slide3 from "./slide3"
import Slide4 from "./slide4"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

const SwiperSlider = ({ className }) => {
  const SliderContainer = useRef(null)

  const slide1Ref = useRef(null)
  const slide2Ref = useRef(null)
  const slide3Ref = useRef(null)
  const slide4Ref = useRef(null)
  function slideChange(swiper) {
    let slides =
      SliderContainer.current.getElementsByClassName("swiper-wrapper")[0]
        .children

    let slideRef = slides[1]
    switch (swiper.activeIndex) {
      case 0:
        slideRef = slides[0]
        break
      case 1:
        slideRef = slides[1]
        break
      case 2:
        slideRef = slides[2]
        break
      case 3:
        slideRef = slides[3]
        break
      case 4:
        slideRef = slides[4]
        break
      case 5:
        slideRef = slides[5]
        break
      default:
        slideRef = slides[1]
    }

    let slideImg =
      slideRef.getElementsByClassName("slider-image")[0].children[0]
    let slideImgCloud = slideImg.getElementsByClassName("svg-cloud-wrapper")

    let slideTitle = slideRef.getElementsByClassName("slider-title")[0]
    let slideContent = slideRef.getElementsByClassName("slider-content")[0]
    let slideCandy = slideContent.getElementsByClassName(
      "gatsby-image-wrapper "
    )[0]

    gsap.set(slideTitle, { x: 100, autoAlpha: 0 })
    gsap.set(slideContent.getElementsByTagName("p"), { x: 100, autoAlpha: 0 })
    gsap.set(slideCandy, { scale: 0, autoAlpha: 0 })
    gsap.set(slideImgCloud, { scale: 0, autoAlpha: 0 })

    let title = gsap.to(slideTitle, {
      x: 0,
      autoAlpha: 1,
      duration: 0.2,
    })
    let contentText = gsap.to(slideContent.getElementsByTagName("p"), {
      x: 0,
      autoAlpha: 1,
      duration: 0.3,
      stagger: 0.1,
    })
    let contentCandy = gsap.to(slideCandy, {
      scale: 1,
      autoAlpha: 1,
      duration: 0.4,
      stagger: 0.1,
    })

    let imgCloud = gsap.to(slideImgCloud, {
      scale: 1,
      autoAlpha: 1,
      duration: 1,
      ease: "elastic.out(1.1, 0.3)",
    })

    let cloudAnimation = gsap.timeline({ duration: 1 })
    cloudAnimation.add(imgCloud)

    let globalTL = gsap.timeline({ delay: 0.5 })
    globalTL.add(title)
    globalTL.add(contentText, "-=0.2")
    globalTL.add(contentCandy, "-=0.3")
    globalTL.add(cloudAnimation, "-=1.2")
    globalTL.play()

    if (swiper.activeIndex % 4 === 0) {
      SliderContainer.current.style.backgroundColor = `${colors["color-bg1"]}`
      SliderContainer.current.style.backgroundImage = `url("./bg_pattern.svg"), url("./bg_pattern.svg")`
      SliderContainer.current
        .getElementsByClassName("swiper-button-prev")[0]
        .style.setProperty(
          "--border",
          `transparent ${colors["color-bg3"]} transparent transparent`
        )
      SliderContainer.current
        .getElementsByClassName("swiper-button-next")[0]
        .style.setProperty(
          "--border",
          `transparent transparent transparent ${colors["color-bg3"]}`
        )
    } else {
      SliderContainer.current.style.backgroundColor = `${colors["color-bg3"]}`
      SliderContainer.current.style.backgroundImage = `url("./bg_pattern3.svg"), url(./bg_pattern3.svg)`
      SliderContainer.current
        .getElementsByClassName("swiper-button-prev")[0]
        .style.setProperty(
          "--border",
          `transparent ${colors["color-bg1"]} transparent transparent`
        )
      SliderContainer.current
        .getElementsByClassName("swiper-button-next")[0]
        .style.setProperty(
          "--border",
          `transparent transparent transparent ${colors["color-bg1"]}`
        )
    }

    setTimeout(() => {
      Array.from(slides).forEach((slide, i) => {
        let imageTofix = slide.querySelectorAll(
          ".slider-content .gatsby-image-wrapper picture img"
        )
        if (imageTofix.length) {
          imageTofix[0].style.opacity = "1"
        }
      })
    }, 300)
  }

  return (
    <StyledSwiper>
      <Swiper
        ref={SliderContainer}
        className="z-index-fix slider-overload-css"
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        loop={true}
        autoplay={true}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={swiper => {
          // console.log(swiper)
          return
        }}
        onSlideChange={swiper => slideChange(swiper)}
        css={{
          height: `100vh`,
        }}
      >
        <SwiperSlide>
          <Slide1 ref={slide1Ref} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide2 ref={slide2Ref} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide3 ref={slide3Ref} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide4 ref={slide4Ref} />
        </SwiperSlide>
      </Swiper>
    </StyledSwiper>
  )
}
const StyledSwiper = styled.div`
  > div.swiper {
    position: relative;
    background: url("./bg_pattern3.svg") no-repeat fixed,
      url("./bg_pattern3.svg") no-repeat fixed;
    background-color: ${colors["color-bg3"]};
    background-position: top left -30vh, bottom 5vh right -10vh;
    background-size: 60vh, 25vh;
    @media ${devices["$large-tabletA"]} {
      background: url("./bg_pattern3.svg") no-repeat fixed;
      background-position: top left -15%, bottom right;
      background-size: 60vw, 0;
    }
  }
`

SwiperSlider.propTypes = {
  className: PropTypes.string,
}

SwiperSlider.defaultProps = {
  className: "",
}

export default SwiperSlider
