import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { text, devices } from "../../scss/js-variables"
import { isMobile, isSafari } from "react-device-detect"

import Facebook from "../../../assets/facebook.svg"
import Instagram from "../../../assets/instagram.svg"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const Section4 = props => {
  const sectionWrapperRef = useRef()
  const textContainerRef = useRef()

  useEffect(() => {
    const section = sectionWrapperRef.current
    const title = textContainerRef.current.getElementsByClassName("title")[0]
    const content =
      textContainerRef.current.getElementsByClassName("content")[0]

    gsap.set(title, { autoAlpha: 0, x: -100, scale: 0.9 })
    gsap.set(content.children, { autoAlpha: 0, x: -100 })

    const constTitleTween = gsap.to(title, {
      autoAlpha: 1,
      ease: "none",
      scale: 1,
      x: 0,
      duration: 0.4,
    })
    const constContentTween = gsap.to(content.children, {
      autoAlpha: 1,
      ease: "none",
      scale: 1,
      x: 0,
      stagger: 0.3,
      duration: 0.8,
    })

    const mainAnimation4 = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        // markers: true,
        toggleActions: "play reverse play reverse",
        id: "yyyyyyyyyyyyyyyyyyyyyyyyyyy",
        start: "top center",
        end: "bottom center",
      },
    })
    mainAnimation4.add(constTitleTween)
    mainAnimation4.add(constContentTween)
  })

  return (
    <StyledSection
      id={props.id}
      ref={sectionWrapperRef}
      isMobileSafari={isMobile && isSafari}
      className={
        (props.className ? props.className : "") +
        " " +
        "main-section fourth container r :c-12:xxs :a-tl:xxs   :a-lc :c-6"
      }
    >
      <div className="z-index-fix" ref={textContainerRef}>
        <h2 className="title">
          <em className="display-block">¡Mantengámonos</em>
          en contacto!
        </h2>
        <div className="content">
          {/* <p>+48 605 096 732</p>
          <p>+48 61 666 13 20</p> */}
          <p>
            <a href="mailto:office@donadelicia.es">
              office
              <span
                css={{
                  fontWetght: 700,
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                }}
              >
                @
              </span>
              donadelicia.es
            </a>
          </p>
          <StyledSocialIcon>
            <li>
              <a
                href=" https://www.facebook.com/Do%C3%B1a-Delicia-102932488869048"
                target="_blank"
                rel="noreferrer"
                aria-label="Link to Facebook"
              >
                <Facebook />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/dona_delicia_galletas/ "
                target="_blank"
                rel="noreferrer"
                aria-label="Link to Instagram"
              >
                <Instagram />
              </a>
            </li>
          </StyledSocialIcon>
        </div>
      </div>
    </StyledSection>
  )
}
const StyledSection = styled.section`
  position: relative;
  color: ${text["white"]};
  background-image: url("./bg_pattern4.svg");
  background-repeat: no-repeat;
  background-attachment: ${props =>
    props.isMobileSafari ? "scroll" : "fixed"};
  background-size: 90vh;
  background-position: top 10vh right -20vh;
  @media ${devices["$large-tabletA"]} {
    background-size: 60vw;
    background-position: top 15vh right -5vw;
  }
  em {
    color: ${text["text-section4"]};
  }
  p {
    line-height: 1em;
    @media ${devices["$phoneA"]} {
      font-size: 1.6em;
    }
  }
  .title {
    font-size: calc(40px + 30 * ((100vw - 320px) / 1000));
    margin-top: 5vh;
    @media ${devices["$large-tabletA"]} {
      margin-top: 0;
    }
  }
`
const StyledSocialIcon = styled.ul`
  font-size: calc(15px + 15 * ((100vw - 320px) / 1000));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0.8em + 10px);
  @media ${devices["$tabletA"]} {
    flex-direction: row;
  }
  > li {
    max-width: 110px;
    margin-right: 0.6em;
    margin-bottom: 0.6em;
    width: 2.7em;
  }
  a svg path {
    fill: #fff;
    transition: 0.3s fill linear;
  }
  a:hover svg path {
    fill: ${text["link-hover"]};
  }
`
export default Section4
